var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-update")]),_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])],1),_c('v-divider'),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 1),expression:"step == 1"}],staticClass:"pa-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v("Desde")]),_c('VSelectWithValidation',{attrs:{"rules":"required","items":_vm.bots,"itemText":"phone","itemValue":"_id"},model:{value:(_vm.editedItem.from),callback:function ($$v) {_vm.$set(_vm.editedItem, "from", $$v)},expression:"editedItem.from"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v("Nombre de la campaña")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Ingresa el nombre"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"body-1 font-weight-bold"},[_vm._v("Segmento")]),_c('v-autocomplete',{attrs:{"items":_vm.segments,"filter":_vm.customFilter,"item-text":"name","item-value":"_id","outlined":"","dense":"","hide-details":"auto","placeholder":"Selecciona un segmento"},model:{value:(_vm.editedItem.segment),callback:function ($$v) {_vm.$set(_vm.editedItem, "segment", $$v)},expression:"editedItem.segment"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Fecha de envío")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_vm._v(" "+_vm._s(_vm.editedItem.scheduleTime)+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Hora de envío")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.editedItem.scheduleTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editedItem, "scheduleTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.editedItem, "scheduleTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","dense":""},model:{value:(_vm.editedItem.scheduleTime),callback:function ($$v) {_vm.$set(_vm.editedItem, "scheduleTime", $$v)},expression:"editedItem.scheduleTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.menu)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Enviar por tandas de")]),_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":""},model:{value:(_vm.editedItem.chunkSize),callback:function ($$v) {_vm.$set(_vm.editedItem, "chunkSize", $$v)},expression:"editedItem.chunkSize"}})],1)],1)],1),_c('TemplateMessagesList',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 2),expression:"step == 2"}],attrs:{"showButtonSelect":true},on:{"onSelectTemplateMessage":function($event){_vm.editedItem.templateMessage = $event.name;
        _vm.step += 1;}}}),(_vm.step == 3)?_c('v-container',{staticClass:"pa-5"},[_c('div',[_c('b',[_vm._v("Nombre de campaña: ")]),_vm._v(_vm._s(_vm.editedItem.name))]),_c('div',[_c('b',[_vm._v("Segmento: ")]),_vm._v(_vm._s(_vm.segments.find(function (el) { return el._id == "editedItem.segment "; }) ? _vm.segments.find(function (el) { return el._id == "editedItem.segment "; }).name : "Sin segmento")+" ")]),_c('div',[_c('b',[_vm._v("Mensaje de Plantilla: ")]),_vm._v(_vm._s(_vm.editedItem.templateMessage))]),_c('div',[_c('b',[_vm._v("Fecha y Hora: ")]),_vm._v(_vm._s(_vm.formatDate(_vm.scheduleDateTime) || "Fecha y hora no válida")+" ")]),_c('div',[_c('b',[_vm._v("Envío por tandas: ")]),_vm._v(_vm._s(_vm.editedItem.chunkSize || "Sin tandas")+" ")])]):_vm._e(),_c('v-card-actions',{attrs:{"rd-actions":""}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.step != 1),expression:"step != 1"}],attrs:{"color":"error"},on:{"click":function($event){_vm.step -= 1}}},[_vm._v("Atrás")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.step != 3),expression:"step != 3"}],attrs:{"color":"secondary"},on:{"click":function($event){passes();
          _vm.step += 1;}}},[_vm._v("Continuar")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 3),expression:"step == 3"}],attrs:{"loading":_vm.loadingButton,"color":"primary"},on:{"click":function($event){return passes(_vm.save)}}},[_vm._v("Finalizar")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }